import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import logLogo from '@channelstudio/loglogo';

import Header from 'components/site/Header';
import Footer from 'components/site/Footer';
import SEO from 'components/site/SEO';

// Whether we have shown the logo
let logoShown = false;

const Layout = ({ pageTitle = '', link = '', children, className = '' }) => {
  const data = useStaticQuery(graphql`
    query siteQuery {
      site {
        siteMetadata {
          title
          author
        }
      }
      allContentfulGeneral {
        nodes {
          name
          address
          metaDescription
        }
      }
    }
  `);

  const generalInfo = data.allContentfulGeneral.nodes.slice(-1)[0];

  useEffect(() => {
    if (!logoShown) {
      logLogo();
      logoShown = true;
    }
  }, []);

  return (
    <>
      <SEO title={pageTitle} description={generalInfo.metaDescription} />
      <Header pageTitle={pageTitle} link={link} />
      <main className={`Main ${className}`}>{children}</main>
      <Footer data={generalInfo} />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
