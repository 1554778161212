import React, { useState, useEffect } from 'react';
import Link from 'components/base/Link';
import classnames from 'classnames';
import Nav from 'components/site/Nav';
import ScrollLockComponent from 'components/utils/scrollLockComponent';
import Logo from 'svg/logo';

const Header = ({ pageTitle, link = '' }) => {
  const [burgerState, setBurgerState] = useState(false);
  const trackEscapeKey = e => {
    if (e.key === 'Escape') {
      setBurgerState(false);
    }
  };
  const linkClick = e => {
    if (pageTitle === '') {
      e.preventDefault();
      setBurgerState(false);
    }
  };
  useEffect(() => {
    // Whenever we hit the escape key,
    // close the burger menu (if open)
    window.addEventListener('keyup', trackEscapeKey);
    return () => {
      window.removeEventListener('keyup', trackEscapeKey);
    };
  }, [setBurgerState]);
  return (
    <>
      <header className="Header relative flex justify-between items-center container">
        <Link
          to={`/`}
          onClick={e => {
            linkClick(e);
          }}
        >
          <span className="Header__logo db logo">
            <Logo />
          </span>
        </Link>
        {pageTitle ? (
          <>
            {link.length ? (
              <Link to={link}>
                <h1
                  className={classnames(
                    'Header__title ttu tc absolute-centered',
                    {
                      'Header__title--show': !burgerState
                    }
                  )}
                >
                  {pageTitle}
                </h1>
              </Link>
            ) : (
              <h1
                className={classnames(
                  'Header__title ttu tc absolute-centered',
                  {
                    'Header__title--show': !burgerState
                  }
                )}
              >
                {pageTitle}
              </h1>
            )}
          </>
        ) : null}
        <button
          aria-label="Open Navigation"
          className={classnames('Header__nav relative', {
            'Header__nav--active': burgerState
          })}
          onClick={() => setBurgerState(!burgerState)}
        >
          <div className="Header__nav-bar Header__nav-bar--top absolute">
            <span></span>
            <span></span>
          </div>
          <div className="Header__nav-bar Header__nav-bar--middle absolute"></div>
          <div className="Header__nav-bar Header__nav-bar--bottom absolute">
            <span></span>
            <span></span>
          </div>
        </button>
      </header>
      <Nav
        toggleNav={burgerState}
        setToggle={setBurgerState}
        pageTitle={pageTitle}
      />
      {burgerState && <ScrollLockComponent />}
    </>
  );
};

export default Header;
