import React from 'react';
import { Link } from 'gatsby';
import AniLink from 'gatsby-plugin-transition-link/AniLink';

const LinkTag = ({
  to = '',
  onClick = () => {},
  className = '',
  children,
  onMouseEnter = () => {},
  onMouseLeave = () => {},
  shouldAnimate = true,
  fromTaxon = false
}) => {
  const internal = /^\/(?!\/)/.test(to);
  if (!internal) {
    return (
      <a
        href={to}
        rel="noopener noreferrer"
        target="_blank"
        className={className}
      >
        {children}
      </a>
    );
  }
  return (
    <>
      {shouldAnimate === true ? (
        <AniLink
          cover
          bg="
          center / 100% 100%   /* position / size */
          no-repeat        /* repeat */
          fixed            /* attachment */
          padding-box      /* origin */
          content-box      /* clip */
          white            /* color */
        "
          to={to}
          direction="left"
          duration={1}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          onClick={onClick}
          className={className}
        >
          {children}
        </AniLink>
      ) : (
        <Link to={to} state={{ fromTaxon: fromTaxon }} className={className}>
          {children}
        </Link>
      )}
    </>
  );
};

export default LinkTag;
