import React from 'react';

const Footer = ({ data }) => {
  const currentYear = new Date().getFullYear();
  return (
    <footer className="Footer flex flex-column flex-row-lg justify-between tc ph3-lg ph4-xl ph5-xxl mb2 mt7 body-copy">
      <ul className="flex flex-column flex-row-lg justify-between">
        <li className="mr6-lg">{data.name}</li>
        <li className="mr6-lg">{data.address}</li>
        <li className="mr6-lg">© {currentYear}</li>
      </ul>
      <a
        href="https://channel.studio"
        target="_blank"
        className="db ttu gradient-hover bn"
        rel="noopener noreferrer"
      >
        Website by Channel Studio
      </a>
    </footer>
  );
};

export default Footer;
