import React from 'react';

const Logo = () => {
  return (
    <svg width="278px" height="95px" viewBox="0 0 278 95" version="1.1">
      <title>logo</title>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="logo" fill="#000000" fillRule="nonzero">
          <polygon
            id="Path"
            points="12.29 0.31 0.62 0.31 0.62 94.69 11.97 94.69 12.29 94.6 12.29 3.62 12.29 2.44"
          ></polygon>
          <polygon
            id="Path"
            points="85.58 0.31 75.53 27.78 71.59 38.53 56.24 80.55 41.16 38.53 37.36 27.78 27.45 0.31 15.43 0.31 14.51 0.31 49.47 94.69 62.47 94.69 98.11 0.31 96.97 0.31"
          ></polygon>
          <polygon
            id="Path"
            points="111.96 87.06 111.96 0.31 100.3 0.31 100.3 2.44 100.3 6.54 100.3 94.69 100.9 94.69 111.12 94.69 111.96 94.69 111.96 92.56"
          ></polygon>
          <polygon
            id="Path"
            points="162.83 0.31 149.82 0.31 114.18 94.69 116.06 94.69 124.24 94.69 126.29 94.69 136.33 66.9 140.26 56.16 155.59 14.17 170.66 56.16 174.45 66.9 184.35 94.69 185.78 94.69 196.34 94.69 197.78 94.69"
          ></polygon>
          <polygon
            id="Path"
            points="211.63 88.78 211.63 0.31 199.97 0.31 199.97 87.6 199.97 92.56 199.97 94.69 205.4 94.69 209.12 94.69 210.81 94.69 211.63 94.69 211.63 92.56"
          ></polygon>
          <polygon
            id="Path"
            points="224.54 83.02 212.88 94.69 215.76 94.69 228.6 94.69 277.38 94.69 277.38 83.02"
          ></polygon>
        </g>
      </g>
    </svg>
  );
};

export default Logo;
