/* eslint jsx-a11y/click-events-have-key-events: 0 */
/* eslint jsx-a11y/no-noninteractive-element-interactions: 0 */
import React from 'react';
import Link from 'components/base/Link';
import classnames from 'classnames';
import FallingBlocks from 'components/modules/FallingBlocks';

const Nav = ({ toggleNav, setToggle, pageTitle }) => {
  return (
    <nav
      className={classnames(
        'Nav ttu fixed flex flex-column items-center justify-center tc w-100',
        {
          'Nav--open': toggleNav
        }
      )}
    >
      <div className="FallingBlocks">{toggleNav && <FallingBlocks />}</div>
      <ul className="Nav__items">
        <li
          className="Nav__item"
          onClick={e => {
            if (pageTitle === 'Catalog') {
              setToggle(false);
            }
          }}
        >
          <Link
            to={`/collection`}
            className={classnames('', {
              'Nav__item--active': pageTitle === 'Catalog'
            })}
          >
            Catalog
          </Link>
        </li>
        <li
          className="Nav__item"
          onClick={e => {
            if (pageTitle === 'Projects') {
              setToggle(false);
            }
          }}
        >
          <Link
            to={`/projects`}
            className={classnames('', {
              'Nav__item--active': pageTitle === 'Projects'
            })}
          >
            Projects
          </Link>
        </li>
        <li
          className="Nav__item"
          onClick={e => {
            if (pageTitle === 'Community') {
              setToggle(false);
            }
          }}
        >
          <Link
            to={`/community`}
            className={classnames('', {
              'Nav__item--active': pageTitle === 'Community'
            })}
          >
            Community
          </Link>
        </li>
        <li
          className="Nav__item"
          onClick={e => {
            if (pageTitle === 'About/ Visit') {
              setToggle(false);
            }
          }}
        >
          <Link
            to={`/about`}
            className={classnames('', {
              'Nav__item--active': pageTitle === 'About/ Visit'
            })}
          >
            About/Visit
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default Nav;
