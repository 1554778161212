import React from 'react';
import { Helmet } from 'react-helmet';

const SEO = ({
  title,
  description,
  imageURL = 'https://www.mediaarchaeologylab.com/opengraph.jpg'
}) => {
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <html lang="en" />
      <link rel="canonical" href="https://www.mediaarchaeologylab.com/" />
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://www.mediaarchaeologylab.com/" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@mediarchaeology" />
      {title ? (
        <title>{`MAL | ${title}`}</title>
      ) : (
        <title>The Media Archaeology Lab</title>
      )}
      {title ? (
        <meta property="og:title" content={`MAL | ${title}`} />
      ) : (
        <meta property="og:title" content="The Media Archaeology Lab" />
      )}
      {title ? (
        <meta name="twitter:title" content={`MAL | ${title}`} />
      ) : (
        <meta name="twitter:title" content="The Media Archaeology Lab" />
      )}
      {description && <meta name="description" content={description} />}
      {description && <meta property="og:description" content={description} />}
      {description && <meta name="twitter:description" content={description} />}
      {imageURL && <meta property="og:image" content={imageURL} />}
      {imageURL && <meta name="twitter:image" content={imageURL} />}
    </Helmet>
  );
};

export default SEO;
