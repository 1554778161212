/* eslint react-hooks/exhaustive-deps: 0 */
import React, { useEffect, useRef } from 'react';

const FallingBlocks = () => {
  let blocksTimeout = null;
  let shouldRun = true;
  const canvas = useRef(null);
  let ctx;
  let canW;
  let canH;
  let w;
  let h;
  let blocks = [];
  const speed = 1;
  const rows = 24;
  const cols = 96;
  let colors = [];
  let offset;
  let incrementer = 0;
  let colorBleedLevels = 4;

  class Block {
    constructor(startX, startY, startColor) {
      this.x = startX;
      this.y = startY;
      this.color = startColor;
    }
    update() {
      this.y += w * speed;
    }
    display() {
      ctx.fillStyle = this.color;
      //adding 1px to each side in order to eliminate
      //white lines showing up between blocks
      ctx.fillRect(this.x - 1, this.y - 1, w + 1, w + 1);
    }
  }

  const setup = () => {
    ctx = canvas.current.getContext('2d');
    updateCanvasSize();
    // Start the blocks 5 screens up,
    // so it takes a while to show up
    offset = 0;
    constructColors();
    makeBlocks();
    // start the loop
    blocksTimeout = setTimeout(() => {
      window.requestAnimationFrame(loop);
    }, 5000);
  };

  const updateCanvasSize = () => {
    canvas.current.width = window.innerWidth;
    canvas.current.height = window.innerHeight;
    canW = canvas.current.clientWidth;
    canH = canvas.current.clientHeight;
    w = canW / rows;
    h = w;
  };

  const loop = () => {
    incrementer++;
    ctx.clearRect(0, 0, canW, canH);

    // Animation :)
    let singleBlockOnScreen = false;
    // update and display all blocks
    for (let i = 0; i < blocks.length; i++) {
      // only update every 5th time
      if (incrementer % 5 === 0) {
        blocks[i].update();
      }
      blocks[i].display();
      // check if any blocks are on screen
      // (or less than the canvas height off screen)
      if (blocks[i].y < h + canH) {
        singleBlockOnScreen = true;
      }
    }
    if (!singleBlockOnScreen) {
      makeBlocks();
    }
    if (shouldRun) {
      window.requestAnimationFrame(loop);
    }
  };

  const constructColors = () => {
    let malColors = [
      'rgb(255, 255, 255)',
      'rgb(248, 188, 76)',
      'rgb(237, 70, 37)',
      'rgb(218, 90, 162)',
      'rgb(65, 36, 103)',
      'rgb(63, 175, 239)',
      'rgb(149, 200, 61)',
      'rgb(255, 255, 255)'
    ];
    let colsPerColor = Math.ceil(cols / malColors.length);
    for (let i = 0; i < cols; i++) {
      // this goes up to cols, need to get it to number of malcolors...
      colors[i] = malColors[Math.floor(i / colsPerColor)];
    }
  };

  const makeBlocks = () => {
    for (let x = rows - 1; x >= 0; x--) {
      for (let y = cols - 1; y >= 0; y--) {
        let i = y * rows + x;
        let startColor;
        // if we're still within the bounds of the colors array
        if (Math.abs(y - cols + 1) < colors.length) {
          // flip a weighted coin to determine if we start blending colors
          let colorAbove = Math.random() * 12 >= 7;
          // if the coin flip comes up heads,
          // we're going to make the block color the color of the section above
          if (colorAbove) {
            startColor =
              Math.abs(y - cols - (colorBleedLevels - 1)) < colors.length
                ? colors[Math.abs(y - cols - (colorBleedLevels - 1))]
                : 'rgb(255, 255, 255)';
          } else {
            // check if the block below it is already the color of the section above
            let blockAboveIndex = i + rows;
            if (blockAboveIndex < blocks.length) {
              startColor = blocks[blockAboveIndex].color;
            } else {
              startColor = colors[Math.abs(y - cols + 1)];
            }
          }
        } else {
          // otherwise just make the block white
          startColor = 'rgb(255, 255, 255)';
        }
        // Actually make the new block
        blocks[i] = new Block(x * w, y * w - cols * w - offset, startColor);
      }
    }
  };

  const onResize = () => {
    updateCanvasSize();
    // On resize we want to give some time
    // before we restart the animation
    offset = canH * 3;
    constructColors();
    makeBlocks();
  };

  useEffect(() => {
    setup();
    window.addEventListener('resize', onResize);
    return () => {
      shouldRun = false;
      window.removeEventListener('resize', onResize);
      clearTimeout(blocksTimeout);
    };
  }, []);

  return <canvas ref={canvas} width="600" height="600" />;
};

export default FallingBlocks;
